.gray_bg_profile {
    height: 85%;
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.previous_orders ul li {
    line-height: 1.7;
    font-size: 1.5rem;
    list-style: inside;
}

.profile_to_left {
    border-right: 1px solid #e1ddef;
    padding-top: 20px;
}

.profile_to_right {
    /* width: 76%;
    float: right;
    margin: 0 1% 0 0; */
}

.signup-extra .center-me {
    text-align: center;
}

.signup-extra ion-icon {
    /* width: 40%; */
    font-size: 3rem;
    cursor: pointer;
}

.signup-extra b {
    margin-top: 20px;
    display: block;
}

.signup-extra button {
    margin-top: 20px;
}

.signup-extra td,
.signup-extra th {
    font-size: 1.2rem;
}

.signup-extra .ant-table-pagination.ant-pagination {
    display: none;
}

.signup-extra p,
.signup-extra b {
    font-size: 1.4rem;
}



.slick-prev {
    left: -15px !important;
    background: #000 !important;
    z-index: 100;
    border-radius: 50%;
}

.slick-next {
    right: -15px !important;
    background: #000 !important;
    border-radius: 50%;
}

.profile_nav ul {
    margin-left: 20px;
}

.profile_nav ul li {
    list-style: none;
    padding-bottom: 10px;
}

.profile_nav ul li a,
.profile_nav ul h4 {
    /* font-weight: 700; */
    color: #111;
    font-size: 1.4rem;
    font-family: 'Gilroy Bold';
    /* font-family: 'DM Sans', sans-serif; */
}

.profile_nav ul li.auth-signout a {
    color: #EF5B5B;
}

.profile_icon {
    font-size: 1.8rem;
    visibility: visible;
    margin-right: 10px;
}

/* .banner_cover {
    background: #ff5a00;
    padding: 15% 15px;
    text-align: center;
    border-radius: 3px;
    width: 30%;
    margin: 0 auto;
    display: block;
} */

.grid-flex-bias .div_block,
.grid-flex .ant-spin,
.grid-flex div,
.grid-flex h3 {
    -webkit-flex: 1 1;
    flex: 1 1;
}

.grid-flex-bias .tab_block {
    -webkit-flex: 2 1;
    flex: 2 1;
}

.banner_cover p {
    color: #fff;
}

.banner_cover h4 {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 700;
}

.banner_cover img {
    display: block;
    margin: 0 auto;
    width: 25rem;
}

.banner_cover button {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 50px;
    color: #000;
    line-height: 1.5;
    font-weight: 500;
    padding: 8px 25px;
}

.profile_to_right div.colored.color_1 {
    background: #cde7e1;
    border: 1px solid #EBEAF0;
}

.profile_to_right div.colored {
    background: #fff;
    border: 2px solid #f7f7f7;
    box-shadow: 0 0 3px rgb(251 251 252 / 20%);
    margin-bottom: 20px;
    padding: 0px 20px;
    border-radius: 5px;
    max-height: 10rem;
    overflow: hidden;
}

.referral-data div.colored {
    min-height: 10rem;
}

.profile_to_right .bg_none,
.transaction_record {
    margin: 35px 0;
}

.empty_history_div {
    width: 40%;
    height: auto;
    margin: 0 auto;
    display: block;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 5%;
}

.empty_history_div img {
    height: 15rem;
    width: auto;
}

.empty_history_div p {
    font-size: 1.4rem;
}

.main_container {
    padding-top: 20px;
}

.main_container_title_div h5 {
    font-size: 1.8rem;
    font-weight: 700;
}

.main_container .slider_div_to_top h2,
.modal-cash-display div.colored h2 {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 5px;
}

h5.sub_table_header {
    font-size: 1.4rem;
}

img.giftbox {
    height: 20rem;
    width: auto;
    margin: 0% auto;
    display: block;
}

.referral.topup {
    text-align: center;
}

.referral.topup h5 {
    line-height: 1.8;
    text-align: center;
    font-family: 'Gilroy Medium';
    font-weight: 400;
    color: #111;
    font-size: 1.4rem;
}

.referral.topup h4 {
    font-size: 1.6rem;
}

.referral.topup button {
    background: var(--green);
    border: 1px solid var(--green);
    color: #fff;
    border-radius: 4px;
    padding: 6px 35px;
    text-align: center;
}

.top-up-panel-title {
    font-size: 1.4rem;
}

.topup-data ul li {
    margin-bottom: 5px;
    line-height: 1.8;
    list-style: inside;
}

p.panel_header {
    font-size: 1.4rem;
}

.referral_text .grid_3 .item h5 {
    font-size: 1.4rem;
}

h3.product_offerings_count {
    background: #202c2c;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.referral_text {
    margin-top: 3%;
}

.table_space_top {
    padding: 30px 0;
}

.referral_text p {
    font-size: 1.4rem;
    line-height: 1.5;
}

.referral textarea {
    width: 100%;
    border: none;
    outline: none;
    text-align: center;
}

.ant-select-selector {
    height: 100% !important;
    padding-top: 8px !important;
}

input.numeric {
    border: 1px solid #D9D9D9 !important;
    border-radius: 2px;
    padding-left: 5px;
}

.cancel {
    display: block;
    /* margin-top: 10px; */
    text-align: center;
    width: 5%;
    height: 2.5rem;
    width: auto;
    border: none;
    background: transparent;
    outline: none;
}

.coverPost div {
    display: table-cell;
    vertical-align: middle;
}

.coverPost {
    background: url('../images/profile/bg.png');
    width: 100%;
    height: 10rem;
    border-radius: 3px;
    background-position: center;
    background-size: cover;
    box-shadow: 0 0 4px rgb(0 0 0 / 40%);
    margin-top: 20px;
    display: table;
    padding: 0 15px;
    padding: 30px 15px;
}

.coverPost button {
    background: #008060;
    border: 1px solid #008060;
    padding: 6px 25px;
    color: #FFF;
    border-radius: 3px;
}

.coverPost p {
    font-size: 1.4rem;
}

.coverPost h3 {
    margin-bottom: 5px;
}

.main_container_inner_div {
    padding: 0%;
    padding-bottom: 5%;
    padding-right: 0%;
}

.main_container_inner_div.for_notifications {
    padding-top: 3%;
}

.modal-cash-display.grid_2 {
    grid-gap: 10px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.modal-cash-display div.colored {
    border-radius: 3px;
    padding: 10px;
}

.slick-track p,
.modal-cash-display div.colored p,
.slider_block p {
    /* color: #C9C9C9; */
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0%;
}

/* .main_container .slider_div_to_top p, .modal-cash-display div.colored p {
    font-size: 1.4rem;
    margin-bottom: 0%;
} */

.slick-track h2,
.slick-track h2 span,
.modal-cash-display div.colored h2,
.slider_block h2,
.slider_block h2 span {
    font-size: 2rem !important;
}

.slick-track h2 span.currency {
    font-size: 1.3rem !important;
}

.profile_to_right div.colored.color_1,
.modal-cash-display div.colored.color_1 {
    background: #EBEAF0;
}

.profile_to_right div.colored .slick-box {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    align-items: center;
}

.profile_to_right div.colored .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    border-radius: 8px;
}

.profile_to_right div.colored.color_1 .icon-box {
    background: #D7F4FF;
}

.profile_to_right div.colored.color_5 .icon-box {
    background: #F5E1C1;
}

.profile_to_right div.colored.color_2 .icon-box {
    background: #F5E1C1;
}

.profile_to_right div.colored.color_3 .icon-box {
    background: #EAE2EA;
}

.profile_to_right div.colored.color_4 .icon-box {
    background: #F5E1C1;
}

.slick-slide img {
    height: auto;
}

.order-history-data-display {
    display: grid;
    grid-template-columns: 3fr 1fr;
    background: rgba(55, 98, 204, 0.05);
    border-radius: 6px;
    padding: 20px 16px;
    row-gap: 50px;
    grid-gap: 20x;
}

.order-history-data-display p {
    margin: 0%;
}

.order-history-data-display button {
    border: 1px solid #222;
    background: transparent;
    padding: 6px 25px;
    border-radius: 3px;
}

.delivered {
    color: #2AA952;
}

.not-delivered {
    color: #F8AF23;
}

.order-history ul li {
    padding: 5px 0px;
}

.order-history a {
    width: 100%;
    display: block;
    text-align: center;
}

.profile_to_right div.colored .slider-main-content {
    border-left: 2px solid #F4F1F2;
    padding: 20px 0px;
    padding-left: 10px;
}

/* .icon-box */

.profile_to_right div.colored.color_5,
.modal-cash-display div.colored.color_5 {
    background: #F4EEE8;
    border: 1px solid #F4EEE8;
}

.profile_to_right div.colored.color_2,
.modal-cash-display div.colored.color_2 {
    background: #F3E9EC;
    border: 1px solid #F3E9EC;
}

.profile_to_right div.colored.color_3 {
    background: #E1E9D6;
    border: 1px solid #E1E9D6;
}

.profile_to_right div.colored.color_4 {
    background: #753FF6;
    border: 1px solid #753FF6;
}

.profile-content {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.short_width {
    width: 60%;
}

.short_width.width-30 {
    width: 40%;
}

.short_width.width-30 .grid-flex p {
    margin-bottom: 0px;
}

.short_width .grid-flex {
    align-items: center;
}

.short_width button.security {
    padding: 8px 12px;
    border-radius: 3px;
    font-size: 1.3rem;
}

.profile_to_right {
    /* overflow: scroll;
    overflow-x: hidden; */
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    padding-bottom: 50px;
}

.ref-modal h3.header_summary {
    font-size: 1.8rem;
    margin-bottom: 5px;
}

.banner_cover {
    float: right;
}

.profile-content .ant-spin-nested-loading,
.profile-content .ant-spin-nested-loading,
.profile-content .ant-spin-container {
    height: 100%;
}

.double-button {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 720px) {
    .signup-extra ion-icon {
        margin-top: 30px;
    }

    .profile_to_left {
        display: none;
    }

    .profile_to_right {
        width: 100%;
        margin: 0%;
        padding: 0%;
        overflow: unset;
    }

    .profile_to_right h5.profile_mobile_title,
    .main_container_title_div h5 {
        margin-top: 15%;
        font-size: 2rem;
        font-weight: 800;
    }

    .main_container_inner_div {
        padding-top: 5%;
    }

    .order_Page .main_container_inner_div,
    .order_Page .konnect_product_display {
        padding-top: 0px;
        margin-top: 0px;
    }

    .main_container {
        padding-top: 0px;
    }

    .no_long_padding .ant-select-selector {
        padding-top: 4px !important;
    }

    .delivery_details .no_long_padding .ant-select-selector {
        padding-top: 10px !important;
    }

    .delivery_details .form-group {
        margin-bottom: 15px;
    }


    .slick-track {
        margin-left: -50px !important;
    }

    .slick-track p {
        /* color: #C9C9C9; */
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 0%;
    }

    /* .slick-track h2, .slick-track h2 span {
        color: #fff;
    } */

    h5.header_summary {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 5px;
    }

    ul.small_div_profile_list li {
        font-size: 1.4rem;
        line-height: 1.6;
        margin-bottom: 10px;
    }

    p.how_it_works {
        font-size: 1.3rem;
        line-height: 1.6;
        margin-bottom: 0%;
    }

    a.link.no_border {
        border: none;
        margin: 10% 0;
        border-bottom: 2px solid #222;
        font-size: 1.3rem;
        color: #111;
    }

    .ant-tabs-tab-btn {
        font-size: 1.4rem;
        font-family: 'Gilroy Bold';
    }

    .ant-tabs-tab {
        padding-top: 0%;
    }

    .coverPost div {
        display: table-cell;
        vertical-align: middle;
    }

    .coverPost {
        background: url('../images/profile/bg.png');
        background: #0D60D8;
        width: 100%;
        height: 15rem;
        border-radius: 3px;
        background-position: center;
        background-size: cover;
        box-shadow: 0 0 4px rgb(0 0 0 / 40%);
        margin-top: 0px;
        display: table;
        padding: 0 15px;
        margin-bottom: 40px;
    }

    .coverPost {
        display: none;
        margin-bottom: 20px;
    }

    .coverPost button {
        background: #008060;
        border: 1px solid #008060;
        padding: 4px 25px;
        color: #FFF;
        border-radius: 3px;
        font-size: 1.3rem;
    }

    .gray_bg_profile {
        min-height: min-content;
        max-height: max-content;
        display: block;
    }

    .empty_history_div {
        width: 80%;
    }

    .profile_to_right {
        margin-bottom: 5%;
    }

    /* .profile_to_right .form-flex-2 {
        display: block;
    } */

    .summary.center-text .anticon {
        font-size: 4rem;
        margin-bottom: 10px;
    }

    .loyalty-success.finishOrder .completeOrder .cart.checkout-page {
        margin-top: 20%;
    }

    .loyalty-success.finishOrder .completeOrder .cart.checkout-page .summary.center-text {
        text-align: center;
    }

    .userprofile {
        height: unset;
        overflow: unset;
    }

    .double-button {
        display: flex;
        flex-direction: column;
        grid-gap: 15px;
        margin-bottom: 30px;
    }

    .double-button button {
        border-radius: 4px;
    }

    p.panel_header,
    .top-up-panel-title,
    .topup-data ul li {
        line-height: 1.8;
        font-size: 1.3rem;
    }

    .wishlist-display .grid_2 .item a {
        display: block;
    }

    .wishlist-display .grid_2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
    }

    .wishlist-display .grid_2 .item h5.product_name {
        padding-left: 0;
        font-size: 1rem;
        font-weight: 400;
        overflow: hidden;
    }

    /* .profile_to_right div.colored .slider-main-content {
        padding: 0px 0px;
        padding-left: 10px;
        display: block;
    } */
    .referal-info .item.grid-4-box.colored {
        padding: 20px 0px;
        padding-left: 20px;
        padding-top: 0px;
    }

    .short_width {
        width: 100%;
    }

    .short_width.width-30 {
        width: 100%;
    }
}