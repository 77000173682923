.landing_text_center {
    text-align: left;
}

.landing_text_center div ul li {
    margin-left: 1em;
}

.mini_header {
    background: linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)), url('../images/mini/landing.webp');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 85vh;
    display: table;
    width: 100%;
}

.mini_header .mini_header_content {
    display: table-cell;
    vertical-align: middle;
}

.mini_header .bg_white {
    background: #fff;
    width: 70%;
    float: right;
    padding: 15px;
    border-radius: 3px;
}

.mini_header .new_header_div_inside h3 {
    color: #fff;
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 5px;
    line-height: 1.3;
    /* width: 40%; */
}

.mini_header .new_header_div_inside a,
.mini_header .new_header_div_inside p {
    color: #fff;
    font-size: 1.8rem;
    line-height: 1.6;
}

.mini_header .new_header_div_inside ul li {
    color: #fff;
    list-style: inside;
    font-size: 1.8rem;
    line-height: 1.6;
}

.faq_container {
    width: 70%;
    margin: 0% auto;
    display: block;
}

.faq_container p,
.faq_container b,
.faq_container ul li,
.faq_container table td,
.faq_container table th {
    font-size: 1.4rem;
    line-height: 1.6;
}

.faq_container table {
    margin-bottom: 30px;
}

.faq_container h5 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.faq_container h4 {
    font-size: 1.6rem;
    margin-top: 30px;
    line-height: 1.6;
}

.minor-header {
    background: #258635;
    background: #063F71;
    height: 200px;
    display: flex;
    align-items: center;
}

.minor-header h3 {
    font-size: 2.4rem;
    color: #fff;
}

.mobile-bottom-nav {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bottom-link {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0px 10px;
}

.mobile-bottom-nav .bottom-link a {
    background: #258635;
    width: 100%;
    padding: 10px;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    font-size: 1.4rem;
}

@media screen and (max-width: 720px) {
    .landing_text_center {
        text-align: center;
    }

    .landing_text_center div ul li {
        margin-left: 0em;
    }

    .new_flex {
        display: block;
        padding: 25% 3%;
    }

    .mini_header .bg_white {
        width: 100%;
        margin-top: 5%;
        margin-bottom: 15%;
    }

    .faq_container {
        width: 100%;
        margin: 0% auto;
        display: block;
    }

    .minor-header {
        align-items: unset;
    }

    .minor-header .header-text {
        padding-top: 90%;
    }

    .faq_container p,
    .faq_container b,
    .faq_container ul li,
    .faq_container table td,
    .faq_container table th {
        font-size: 1.4rem;
        line-height: 1.8;
    }

    .mini_header .new_header_div_inside a,
    .mini_header .new_header_div_inside p {
        color: #fff;
        font-size: 1.4rem;
        line-height: 1.8;
    }

    .mini_header .new_header_div_inside ul li {
        color: #fff;
        list-style: inside;
        font-size: 1.4rem;
        line-height: 1.8;
    }

    .mini_header .new_header_div_inside h3 {
        color: #fff;
        font-size: 2.3rem;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 1.3;
    }

    .new_flex.contactus {
        display: flex;
        flex-direction: column;
    }

    .mini_header .mini_header_content {
        padding: 15% 0px;
    }
}