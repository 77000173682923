.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.bill.wise9ja.minor h1 {
    font-family: 'Gilroy Bold';
    font-size: 3.5rem;
}

.bill.wise9ja.minor h1 span {
    font-size: 4rem;
    font-family: inherit;
}

.bill.wise9ja.minor .hero ul li {
    font-size: 1.6rem;
    line-height: 1.8;
    list-style: inside;
}

.bill.wise9ja .hero .grid_2 img.wise9ja_header {
    width: 65%;
    height: auto;
}

.plan_card {
    width: 250;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 32px;
    padding-right: 32px;
    background: #f0f0f0;
    border-radius: 10px
}

.plan_title {
    color: #0f0f0f;
    text-align: center;
    /* letter-spacing: 2px; */
    /* font-weight: bold; */
    font-size: 1.5rem !important;
}

.bill.wise9ja.minor h1.plan_price {
    font-size: 2.3rem;
    text-align: center;
    /* font-weight: bolder; */
    margin-bottom: 10px;
}

.bill.wise9ja.minor h1.max_plan_price {
    font-size: 2.3rem;
    text-align: center;
    /* font-weight: bolder; */
}

.bill.wise9ja.minor h1 span.small_currency {
    font-size: 1.5rem !important;
}

.bill.wise9ja.minor h1 span.small_currency.minmax {
    text-transform: lowercase;
}

.final-wise {
    background: #F8F9FE;
    margin-top: 5%;
    padding-top: 5%;
    text-align: center;
    padding-bottom: 5%;
}

.final-wise .grid-3 img {
    width: auto;
    height: 5rem;
    margin-bottom: 15px;
}

.final-wise h3 {
    font-size: 3.5rem;
    margin: 0px auto;
    display: block;
    width: 50%;
    margin-bottom: 10px;
    line-height: 1.4;
}

.final-wise p {
    font-size: 1.5rem;
    width: 55%;
    line-height: 1.7;
    margin: 0px auto;
    display: block;
    margin-bottom: 60px;
}

.final-wise .grid-3 p {
    font-size: 1.4rem;
    line-height: 1.6;
    width: 60%;
    margin-bottom: 0px;
}

.get_started {
    padding: 8px 5px;
    width: 100%;
    font-size: 16px;
    border-radius: 5px;
    height: 100%;
    margin-top: 1rem;
    background-color: #258635 !important;
    border: 0px !important;
    font-weight: 500;
}

.divider {
    border: 0.7px solid #ccc;
}

.wise9ja-props {
    margin-top: 5%;
}

.card-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.card-list .first-card {
    background: #F0ECFF;
}

.card-props-width {
    width: 80%;
    margin: 0px auto;
    display: block;
}

.card-list .props-img {
    width: 100%;
    height: 20rem;
    position: relative;
    /* background: red; */
}

.wise9ja-props-text {
    height: 20rem;
    display: block;
}

.card-props-width img.prop-img {
    width: 80%;
    height: auto;
    position: absolute;
    bottom: 0px;
    top: 0px;
    right: 0px;
    height: 25rem;
    object-fit: cover;
    object-position: top;
}

.card-list p {
    font-size: 1.6rem;
    line-height: 1.6;
    color: #6e80a3;
}

.card-list h3 {
    font-size: 2rem;
    margin-bottom: 0px;
    width: 50%;
}

.card-list .card-prop-data {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0px;
    position: relative;
    overflow: hidden;
    height: max-content;
    border-radius: 4px;
}

@media screen and (min-width: 720px) {
    .card-prop-data.long-one .wise9ja-props-text {
        height: 25rem;
    }

    .third-card.card-prop-data {
        margin-top: -10%;
    }
}

.card-list .card-prop-data a {
    color: #0f0f0f;
    font-size: 1.4rem;
    border-bottom: 2px solid #0f0f0f;
    width: max-content;
    display: block;
    padding: 0px;
}

.card-props-width p.prop-title,
.card-props-width .long-card p.prop-title {
    font-size: 1.3rem;
    margin-bottom: 5px;
}

.card-list .second-card {
    background: #E5FAF5;
}

.card-list .third-card {
    background: #F0F2F6;
}

.card-list .fourth-card {
    background: #FDF2EE;
}

.long-card {
    background: #F9F9F9;
    margin-top: 5%;
    border-radius: 4px;
}

.card-props-width .long-card p.card-story {
    padding-bottom: 20px;
}

.long-card .props-img {
    width: 100%;
    height: 100%;
    position: relative;
}

.long-card .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.card-props-width .long-card {
    position: relative;
    overflow: hidden;
    padding-left: 30px;
    padding-top: 60px;
}

.card-props-width .long-card img {
    width: 65%;
    bottom: 0%;
    height: auto;
    top: unset;
    right: 0px;
    /* object-position: top;
    object-fit: cover; */
}

.card-props-width .long-card p {
    font-size: 1.6rem;
    line-height: 1.8;
    width: 80%;
}

.card-props-width .long-card h3 {
    font-size: 2.3rem;
    width: 70%;
}

@media screen and (max-width: 720px) {
    .wise9ja-props {
        padding-top: 15%;
    }

    .wise9ja-props .card-props-width {
        width: 100%;
    }

    .wise9ja-props .grid-2 {
        display: flex;
        flex-direction: column;
    }

    .wise9ja-props .card-list {
        display: flex;
        flex-direction: column;
    }

    .wise9ja-props .card-list {
        display: flex;
        flex-direction: column;
    }

    .wise9ja-props .card-list h3 {
        width: 100%;
        font-size: 1.8rem;
        margin-bottom: .5em;
    }

    .final-wise {
        text-align: left;
        margin-top: 15%;
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .final-wise h3,
    .final-wise p,
    .final-wise .grid-3 p {
        width: 100%;
    }

    .final-wise .grid-3 {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
    }

    .card-props-width .long-card img {
        width: 60%;
        right: 0px;
    }

    .card-props-width .long-card p {
        margin-bottom: 0px;
        right: 0px;
    }

    .card-props-width .long-card {
        padding: 20px;
        padding-top: 30px;
        padding-bottom: 0px;
    }

    .long-card .props-img {
        height: 15rem;
    }

    .long-card .props-img img {
        height: 15rem;
    }

    .card-props-width .long-card p,
    .card-props-width .long-card h3 {
        width: 100%;
    }

    .card-props-width .long-card p {
        font-size: 1.5rem;
        line-height: 1.8;
        color: #6e80a3;
    }

    .card-props-width .long-card h3 {
        font-size: 1.8rem;
    }

    .bill .hero .contain .grid_2 {
        display: flex;
        flex-direction: column-reverse;
        flex-direction: column;
        grid-gap: 20px;
    }

    /* .bill .hero {
        padding-top: 15%;
    } */
    .bill.wise9ja.minor h1 {
        font-family: 'Gilroy Bold';
        font-size: 3rem;
    }

    .bill.wise9ja.minor h1 span {
        font-size: 3rem;
        font-family: inherit;
    }

    .bill.wise9ja.minor p {
        font-size: 1.4rem;
    }

    .bill.wise9ja.minor .category-data.carousel-with-margin p {
        font-size: 1.2rem;
    }

    .bill.wise9ja .hero .grid_2 img.wise9ja_header {
        width: 80%;
        margin: unset;
    }

    .hero .icons-list {
        grid-gap: 10px;
    }

    .bill.bill-page .hero .contain .icons-list a p {
        font-size: 1.2rem;
    }
}