@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Söhne-Regular";
  src: url("../assets/fonts/Sohne/TestSöhne-Leicht.otf");
}

@font-face {
  font-family: "Söhne-Bold";
  /* src: url('../assets/fonts/TestSöhne-Fett.otf'); */
  src: url("../assets/fonts/Sohne/TestSöhne-Dreiviertelfett.otf");
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  /* font-family: 'Söhne-Regular'; */
  box-sizing: border-box;
}

.app-display {
  max-width: 1408px;
  margin: 0px auto;
  display: block;
  background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: 'Söhne-Bold'; */
  font-weight: normal;
}

.main-page {
  width: 100%;
  display: grid;
  padding-right: 60px;
  padding-bottom: 5rem;
  /* grid-template-columns: 1fr 4fr;
    grid-gap: 60px; */
}

.side-nav {
  border-right: 1px solid #eff1f6;
  width: 23%;
  position: fixed;
  height: 100%;
}

.main-sect {
  width: calc(100% - 23%);
  padding-left: 60px;
  margin-left: 23%;
}

.grid-flex {
  display: flex;
  justify-content: space-between;
}

.side-nav ul li {
  margin-bottom: 24px;
  color: #56616b;
  font-family: "Söhne-Regular";
  list-style: none;
}

.side-nav ul li span.first-spaner {
  padding-left: 60px;
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.side-nav ul li.active-menu {
  color: #ff5403;
  border-left: 3px solid #ff5403;
}

.recharts-surface {
  margin-left: -4%;
}

.recharts-cartesian-axis-line {
  stroke: none;
}

.side-nav ul li span svg {
  margin: 0px auto;
  display: block;
}

.side-nav ul li span.first-span {
  width: 5px;
  height: auto;
  flex: 1;
}

.side-nav ul li p {
  margin-bottom: 0px;
  font-size: 16px;
  flex: 20;
}

ul li:last-child {
  margin-bottom: 0px;
}

ul h4 {
  padding-left: 60px;
  padding-top: 32px;
  padding-bottom: 20px;
  color: #56616b;
  font-size: 12px;
  font-family: "Söhne-Regular";
}

.tag-box {
  display: flex;
  grid-gap: 12px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.tag-box .tag {
  border: 1px solid #eff1f6;
  border-radius: 100px;
  padding: 12px 16px;
  cursor: pointer;
}

.tag-box .tag p {
  font-size: 14px;
  line-height: 16px;
  color: #31373d;
  margin-bottom: 0px;
}

.tag-box .tag .active {
  background: #ffddcd;
  border: 1px solid #ff5403;
}

.tag-box .tag .active p {
  color: #ff5403;
  font-family: "Söhne-Bold";
}

.tag-box .tag.active-tag {
  background: #258635;
  border: 1px solid #258635;
}

.tag-box .tag.active-tag p {
  color: #fff;
}

.area-chart-sect {
  border: 1px solid #eff1f6;
  border-radius: 24px;
  padding: 32px 24px;
  margin-bottom: 24px;
}

/* page-title */
.page-title {
  margin: 22px 0px 46px;
  font-size: 20px;
  line-height: 24px;
}

.page-greeting-title {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 32px;
}

p.page-greetinig-summary {
  margin-bottom: 0px;
  color: #31373d;
  font-size: 14px;
  line-height: 22px;
}

.analytics {
  color: #ff5403;
  font-size: 14px;
  line-height: 22px;
}

.area-chart-sect h2 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #131316;
}

.area-chart-sect h5 {
  margin-bottom: 24px;
  font-family: "Söhne-Regular";
  font-size: 14px;
  line-height: 20px;
  color: #31373d;
}

.area-chart-sect h1 {
  margin-bottom: 42px;
  font-size: 48px;
  line-height: 57.6px;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.grid-2 .quick-data {
  border: 1px solid #eff1f6;
  border-radius: 24px;
  padding: 24px 24px 44px;
}

.grid-2 .quick-data ul li {
  margin-bottom: 19px;
  display: flex;
  grid-gap: 8px;
  align-items: center;
}

.grid-2 .quick-data ul li:last-child {
  margin-bottom: 0px;
}

.grid-2 .quick-data ul li img {
  border-radius: 4px;
  width: 15%;
}

.quick-data .grid-flex.data-title {
  margin-bottom: 40px;
}

.quick-data .grid-flex.data-title h3 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 24px;
}

/* .grid-2 .quick-data ul li .flag-box span {
    font-size: 40rem;
} */

.location-name {
  font-size: 14px;
  line-height: 24px;
}

.quick-data .grid-flex.data-title {
  align-items: baseline;
}

.flag-box {
  display: flex;
  grid-gap: 12px;
  align-items: center;
}

.flag-box span b {
  padding-left: 8px;
}

.color-bar {
  background: #599eea;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.grid-flex.data-title a {
  color: #ff5403;
  font-size: 14px;
  line-height: 22px;
}

.user-details {
  display: flex;
  align-items: center;
  padding-left: 60px;
  /* padding-top: 198px; */
  grid-gap: 12px;
}

.user-det.grid-flex {
  padding-top: 35px;
  align-items: center;
  padding-right: 25px;
}

.user-details p {
  margin-bottom: 0px;
  color: #56616b;
  font-size: 15px;
}

.user-details img {
  border-radius: 50%;
}

.area-chart-sect .grid-flex img {
  width: 25px;
  height: auto;
}

@media screen and (max-width: 720px) {
  .side-nav {
    display: none;
  }

  .main-page {
    display: block;
    padding-right: 0px;
  }

  .main-sect {
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;
    padding: 10px 1.5%;
  }

  .tag-box {
    flex-wrap: wrap;
  }

  .grid-2.quick-data-grid {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
  }

  .grid-flex.sec-grid {
    flex-direction: column-reverse;
  }

  .recharts-surface {
    margin-left: -10%;
  }

  .grid-flex.sec-grid .recharts-surface {
    margin-left: -40%;
  }

  .grid-flex.sec-grid ul {
    margin-top: 30px;
  }

  .grid-2 .quick-data ul li img {
    border-radius: 4px;
    width: 7%;
  }

  .area-chart-sect {
    padding: 32px 12px;
  }

  .grid-flex.greeting-flex {
    flex-direction: column;
    align-items: unset !important;
    grid-gap: 20px;
  }
}

@media screen and (min-width: 1500px) {
  .side-nav {
    max-width: 300px;
    overflow: hidden;
  }
}

.summary-data .grid-2 {
  align-items: center;
}

.summary-data .shop-grid-cover {
  border: 1px solid #e7e8f2;
  padding: 26px 20px;
  border-radius: 17px;
  margin-bottom: 32px;
}

.summary-data .shop-grid-cover:nth-child(2) {
  margin-bottom: 0px;
}

.summary-data .shop-grid-cover .grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.grid-flex {
  display: flex;
  justify-content: space-between;
}

.grid-5 {
  display: grid;
  grid-gap: 14px;
  grid-template-columns: repeat(5, 1fr);
  white-space: unset;
}

.grid-4 {
  display: grid;
  grid-gap: 14px;
  grid-template-columns: repeat(4, 1fr);
}

.summary-cover {
  height: 80px;
  width: 80px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary-image-space h4 {
  margin-bottom: 0px;
}

.shop-summary-data-display p {
  font-size: 1.4rem;
}

.summary-text-space {
  padding-left: 30px;
}

.summary-cover.cover-1 {
  background: rgba(26, 168, 233, 0.08);
}

.summary-cover.cover-2 {
  background: rgba(1, 5, 138, 0.09);
}

.summary-image-space {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.border-display {
  border-right: 0.05px solid #e7e8f2;
}

.summary-grid .grid-2 .shop-summary-data-display .grid-2 {
  display: grid;
  grid-template-columns: 2fr 1.5fr;
}

.bg-blue-message {
  background: #dfe5fd;
  padding: 12px 15px 12px;
  border-radius: 12px;
}

.bg-blue-side {
  text-align: center;
  border-left: 1px solid #d2d8ee;
}

.bg-blue-grid p {
  font-size: 1.2rem;
}

.bg-blue-side p {
  margin-bottom: 0px;
}

.bg-blue-main p {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.bg-blue-main h3 {
  font-size: 1.6rem;
}

.bg-blue-title h4 {
  font-size: 1.8rem;
}

.bg-blue-side h3 {
  font-size: 1.6rem;
  margin-bottom: 0px;
}

.icon-bg {
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
}

.icon-bg img {
  width: 45%;
  height: auto;
}

.bg-blue-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.display-grid {
  display: grid;
  grid-template-columns: 3fr 1.3fr;
  grid-gap: 40px;
}

.user-dashboard .ant-table-pagination.ant-pagination {
  display: none;
}

.user-dashboard td,
.user-dashboard th {
  font-size: 1.2rem;
}

.user-dashboard .dash-table {
  margin-bottom: 30px;
}

.report-bg {
  background: #eceff7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.report-bg.active {
  background: #0000a4;
}

.referral-sect-div {
  display: flex;
  justify-content: space-between;
  padding: 14px 16px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.referral-sect-div p {
  margin-bottom: 0px;
  font-size: 1.3rem;
}

.referral-sect-div._1 {
  background: #ddf4ff;
  border: 1px solid rgba(84, 174, 255, 0.4);
}

.referral-sect-div._2 {
  background: #fff8c5;
  border: 1px solid rgba(212, 167, 44, 0.4);
}

.referral-sect-div._3 {
  background: #ffebe9;
  border: 1px solid rgba(255, 129, 130, 0.4);
}

.referral-sect-div._4 {
  background: #dafbe1;
  border: 1px solid #dafbe1;
}

.transactions-img-cover {
  background: #dfe5fd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transactions-img-cover img {
  width: 50%;
  height: auto;
}

.overview-single-cover {
  display: flex;
  grid-gap: 8px;
  align-items: center;
  margin-bottom: 30px;
}

.overview-single-cover:nth-child(4) {
  margin-bottom: 0px;
}

.form-width-50 {
  width: 50%;
}

.auth-page .form-width-50 {
  margin-bottom: 5%;
}

.security-update {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-grid-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.top-message.dashboard-grid-flex {
  padding-top: 5%;
}

.top-message.dashboard-grid-flex h4 {
  font-size: 2.2rem;
  margin-bottom: 10px;
}

.top-message.dashboard-grid-flex p {
  font-size: 1.5rem;
}

.overview-single-cover p {
  margin-bottom: 0px;
  font-size: 1.4rem;
}

.overview-single-cover h4 {
  margin-bottom: 0px;
  font-size: 1.6rem;
}

.bg-blue-light {
  background: rgba(55, 98, 204, 0.05);
  border: 1px solid rgba(55, 98, 204, 0.05);
  padding: 10px 25px;
  border-radius: 3px;
  font-size: 1.4rem;
}

.bg-blue {
  background: #0000a4;
  border: 1px solid #0000a4;
  padding: 10px 25px;
  border-radius: 3px;
  font-size: 1.4rem;
  color: #fff;
}

.dashboard-grid-message-cover .grid-flex {
  grid-gap: 10px;
}

.dashboard-grid-message-cover .grid-flex img {
  width: 20%;
  height: auto;
}

.dashboard-grid-message-cover .grid-flex img.avatar {
  width: 50%;
  height: auto;
}

.dashboard-grid-message-cover .grid-flex button {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.dashboard-grid-flex p {
  font-size: 1.4rem;
  margin-bottom: 0px;
}

.dashboard-grid-flex h4 {
  font-size: 1.6rem;
  margin-bottom: 0px;
}

.overview-image-block {
  display: flex;
  grid-gap: 22px;
}

.display-grid-side-display {
  border-radius: 18px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  padding: 20px 28px;
}

.display-grid-side-display .grid-4 p,
.display-grid-side-display .grid-4 a p {
  font-size: 1.3rem;
  color: #111111;
}

.report-bg {
  margin-bottom: 5px;
}

.area-chart-cover {
  box-shadow: 10px 10px 80px rgba(63, 39, 102, 0.1);
  border-radius: 24px;
  padding: 15px 0%;
}

.area-chart-cover h4 {
  padding-left: 20px;
  padding-bottom: 10px;
}

.area-block {
  margin-top: 30px;
  margin-bottom: 30px;
}

.bar-chart-showcase {
  background: #fff;
  border: 1px solid #e7e8f2;
  border-radius: 6px;
}

.bar-chart-block {
  background: rgba(244, 241, 242, 0.5);
  border: 1px solid #e7e8f2;
  border-radius: 17.0118px;
  padding: 24px 20px;
}

.bar-chart-body p {
  font-size: 1.4rem;
  padding-left: 10px;
}

.dashboard-grid-main-message {
  margin-top: 30px;
  margin-bottom: 60px;
}

.pie-indicator {
  background: #4455a2;
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 50%;
}

.piechart-key-display div {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.piechart-key-display div p {
  margin-bottom: 0px;
  font-size: 1.3rem;
}

.light-orange {
  background: #fcbf87;
}

.dark-orange {
  background: #a27744;
}

.light-blue {
  background: #7f8ec3;
}

.dark-blue {
  background: #4455a2;
}

.piechart-key-display {
  margin-top: 30px;
  margin-bottom: 30px;
}

.order-flex {
  display: grid;
  grid-template-columns: 1fr 2fr 1.5fr 0.5fr;
  align-items: center;
  grid-gap: 10px;
}

.order-flex a {
  font-size: 1.4rem;
}

.order-flex p {
  margin-bottom: 0px;
  font-size: 1.4rem;
}

.order-flex h5 {
  /* margin-bottom: 0px; */
  font-size: 1.5rem;
  width: 80%;
}

@media screen and (max-width: 720px) {
  .display-grid {
    display: flex;
    flex-direction: column;
  }

  .display-grid .grid-2 {
    display: flex;
    flex-direction: column;
  }

  .summary-grid .grid-2:nth-child(1) {
    display: flex;
    flex-direction: column;
  }

  .area-block.grid-2 {
    display: flex;
    flex-direction: column;
  }

  .dashboard-grid-flex {
    flex-direction: column;
    align-items: unset;
    grid-gap: 20px;
  }

  .display-grid-side-display .grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .display-grid-side-display {
    border-radius: 18px;
    box-shadow: none;
    padding: 20px 0px;
  }

  .dashboard-grid-message-cover .grid-flex {
    justify-content: unset;
  }

  .top-message.dashboard-grid-flex {
    padding-top: 15%;
  }
}
