.hero .icons-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    text-align: center;
    margin-bottom: 0px;
}

.hero .icons-list button p {
    width: 100%;
    margin-bottom: 0%;
    font-size: 1.5rem;
    color: #000;
}

.hero .icons-list button {
    padding: 8px 25px;
}

.hero .bills-margin-top a {
    font-size: 1.5rem;
}

ion-icon.bills_icons {
    font-size: 30px;
}

.grid_story_box.first-color {
    background: rgba(55, 98, 204, 0.05);
    border: 1px solid rgba(55, 98, 204, 0.05);
    padding: 20px;
}

.grid_story_box.second-color {
    background: rgba(248, 175, 35, 0.05);
    border: 1px solid rgba(248, 175, 35, 0.05);
    padding: 20px;
}

.grid_story_box.third-color {
    background: rgba(221, 43, 86, 0.04);
    border: 1px solid rgba(221, 43, 86, 0.04);
    padding: 20px;
}

.konnect_props.bill_props .first_show h3 {
    margin-bottom: 20px;
}

.bill_props .grid_2 {
    align-items: unset;
}

@media screen and (max-width: 720px) {
    .hero .icons-list button {
        padding: 8px 15px;
        /* display: block; */
    }

    ion-icon.bills_icons {
        font-size: 25px;
    }

    .bill_props .grid_2 {
        display: block;
    }

    /* .konnect_props .first_show h3, .konnect_props .bill_props h3 {
        width: 90%;
    } */
    .grid_story_box {
        border: none;
        padding: 0px;
        padding-bottom: 0px;
    }

    .minor .konnect_props .grid_3 {
        grid-gap: 20px;
    }

    .minor .konnect_props .bill_props p {
        margin-bottom: 20px;
    }

    .minor .konnect_props .grid_3 p {
        margin-bottom: 0px;
    }

    .bill .hero {
        padding: 50px 0px;
        /* padding-bottom: 40px; */
    }

    .header img.wise9ja_header {
        width: 100%;
    }
}