.slick-slide img {
  height: 30px !important;
}

.avatar-uploader .ant-upload.ant-upload-select {
  width: 100% !important;
  height: 300px !important;
}

.restaurnat_profile_button {
  @apply px-14 py-6 rounded-full text-2xl font-semibold tracking-wider;
}

.restaurant_qac {
  border: 1px solid black;
  padding: 40px;
  border-radius: 50px;
  background: #fdf4d7;
}

.restaurant_profile_note {
  padding: 40px;
}

.qac_text {
  font-size: 1.5rem;
}

.food-card {
  width: 100%;
  max-width: 240px; /* Control max-width for better appearance */
  margin: 0 auto; /* Center the card */
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.food-info {
  padding: 20px 10px 0px 10px;
}

.not-available .ant-card-cover img {
  opacity: 0.5;
}

.not-available-tag {
  position: absolute;
  top: 10px;
  left: 10px;
}

.price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.food-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.add-menu-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.add-menu-button {
  @apply bg-black border-0 text-white font-semibold px-6 py-8 flex items-center justify-center transition-colors duration-300 text-lg;
}

.profile_category_title {
  @apply text-2xl font-semibold tracking-wider mb-5;
}

.add-menu-button:hover,
.add-menu-button:focus {
  background-color: #333;
  border-color: white;
  color: white;
}

.new-menu-drawer {
  padding: 40px !important;
}

.menu-update-btn {
  background-color: #45a049;
  border: 2px solid black;
  color: white;
  font-weight: 600;
  padding: 20px 32px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  transition: background-color 0.3s, border-color 0.3s;
  margin-top: 20px;
}

.menu-update-btn:hover,
.menu-update-btn:focus {
  border-color: none;
}

.custom-timepicker {
  height: 50px;
  font-size: 18px;
}

.form-flex-2 .ant-form-item {
  flex: 1; /* Makes the form items take equal space */
}

.custom-timepicker .ant-picker-input > input {
  font-size: 18px;
}

.custom-timepicker .ant-picker {
  height: 50px;
}


.becomeRestaurant .ant-modal-body{
  /* padding: 20px; */
  background: #FDF4D7;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  width: 100%;
}



/* Mobile Dash Nav */
.mobile-nav-tabs {
  white-space: nowrap;
  overflow-x: auto;
  margin-top: 15%;
  font-size: 2rem;
}

.mobile-nav-tabs .ant-tabs-nav {
  display: flex;
  justify-content: space-around;
}

.mobile-nav-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #258635 !important;
}

.mobile-nav-tabs .ant-tabs-tab {
  color: #111111;
}

.mobile-nav-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #258635;
}

.mobile-nav-tabs .ant-tabs-ink-bar {
  background-color: #258635 !important; /* Change the underline to green */
}

.flex-side {
  @apply grid md:grid-cols-2 gap-1 w-full grid-cols-1;
}

/* Mobile adjustments */
@media (max-width: 576px) {
  .food-card {
    max-width: 200px;
  }
}

@media (max-width: 768px) {
  .restaurant_qac {
    padding: 30px;
  }

  .restaurant_profile_note {
    padding: 30px;
  }
}

/* Restaurant Active Page CSS */
.wrapperBg {
  background-image: url("../images/restaurant/wrapperBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 150px;
  max-height: 100px;
  min-height: 60px;
}

.headerBg {
  background-image: url("../images/homepage/restaurant_slide_1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 250px;
  max-height: 250px;
}

.singlePageHeader{
  background-image: url("../images/restaurant/SP_header.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;

}

.restaurantBanner {
  background-image: url("../images/restaurant/restaurantBanner.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
}

/*.singleRestaurant_img{*/
/*  background-image: url("../images/restaurant/restaurant_img.png");*/
/*  background-position: center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  width: 100%;*/
/*  height: 300px;*/
/*  max-height: 300px;*/
/*  min-height: 300px;*/
/*  border-radius: 30px;*/
/*}*/

/* SinglePage */
.flip-container {
  perspective: 1000px; /* Creates a 3D space for the flip effect */
  width: 100%; /* Adjust width as needed */
  height: 100%;
}

.flippable-card {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s; /* Smooth transition for the flip */
}

.flip-container.flipped .flippable-card {
  transform: rotateY(180deg); /* Flip effect */
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hides the back side when flipped */
}

.card-back {
  transform: rotateY(180deg); /* Start the back side rotated */
}



.checkoutDrawer .ant-drawer-content-wrapper{
  width: 720px !important;
}

.rest_card_body .ant-card-cover img {
  width: 100%;
  height: 100px;
  background-size: cover;
}

.rest_card_body .ant-card-body{
  padding: 1px ;
}

.rest_card_body.checkout_ .ant-card-body{
  width: 100%;
  /*display: flex;*/
  padding: 24px;
  /*align-items: center;*/
}

.amount_box{
  border: 1px solid green !important;
  color: black !important;
}

.circle_btn{
  width: 10px !important;
  height: 10px !important;
}

.circle_btn.ant-btn-icon-only {
  height: 25px !important;
}

.restaurantBack ion-icon {
  font-size: 2.5rem;
}

.restaurantBack_width {
  width: 50%; /* Default for screens wider than 320px */
}


/*REQUEST TRANS CODE*/


.otp-input-box {
  display: flex;
  grid-gap: 10px;
  /* display: grid;
  grid-template-columns: repeat(5, 1fr); */
}

.otp-input-box input {
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.otp-input-box .otp-input-cover {
  width: 100%;
}

.ant-drawer .trans-code .ant-drawer-body {
  padding: 20px 0px;
}

.ant-drawer .trans-code .ant-drawer-body a {
  font-size: 1.3rem;
  font-family: 'Gilroy Medium';
}

.modal-input-box .otp-inner-div {
  display: flex;
  grid-gap: 20px;
  width: 90%;
  margin: 0px auto;
}

.modal-input-box .otp-inner-div input {
  text-align: center;
}

.modal-input-box input {
  width: 100% !important;
  height: 5.5rem;
}

.otp-cover .modal-input-box .otp-input-cover div {
  grid-gap: 5px;
}

.modal-input-box div {
  grid-gap: .5px;
}

.modal-input-box .otp-inner-div {
  display: flex;
  grid-gap: 20px;
  width: 90%;
  margin: 0px auto;
}

.modal-input-box .otp-inner-div input {
  text-align: center;
}

.otp-inner-div input[type="password"] {
  border: 1px solid #e1e1e5 !important;
}

.onscreen .hg-theme-default .hg-button {
  background: transparent;
  box-shadow: none !important;
  /* border-bottom: 2px solid #222222; */
  border: none;
  border-radius: 0px;
  font-family: 'Gilroy Bold';
}

.onscreen .hg-theme-default {
  /* background: transparent; */
  padding: 5px;
  border-radius: 0px;
}

.hg-theme-default.hg-layout-numeric .hg-button {
  background: #fff;
  border-radius: 5px;
}

.numeric-cover {
  background: #ececec;
  width: 100%;
  text-align: center;
  padding: 10px 0px 5px;
}

.modal-input-box .otp-input-cover div {
  grid-gap: 15px;
}

.numeric-cover a {
  font-size: 1.2rem;
  margin-bottom: 0px;
  color: #222222;
}

.center {
  text-align: center;
}

/**/

.foodImgArea img {
  width: 100%;
  min-width: 225px;
  height: 200px;
  min-height: 200px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  max-width: 300px;
}

.select_search .ant-select-selector,
.rest_searchDrawer .ant-select-selector{
  /*color: white !important;*/
  /*outline: none;*/
  /*border: none;*/
  /*border-color: transparent !important;*/
  border-radius: 50px !important;
  padding:  1.5rem 2rem !important;
  background-color: #9B9B9B !important;
}

/* Placeholder text color */
.select_search .ant-select-selection-placeholder,
.select_search .ant-select-arrow,
.select_search .ant-select-item,
.rest_searchDrawer .ant-select-arrow{
  /*color: white !important;*/
  font-weight: 700;
}

/* Dropdown menu background */
.select_search .ant-select-dropdown {
  /*background-color: transparent;*/
}


@media (max-width: 320px) {
  .restaurantBack_width {
    width: 80%; /* For screens 320px and below */
  }
  .foodImgArea img {
    min-width: 100%;
  }
}

@media (max-width: 767px) {
  .checkoutDrawer .ant-drawer-content-wrapper{
    width: 100% !important;
  }
}


@media (min-width: 1300px) {
  .foodImgArea img {
    min-width: 408px;
  }
}



.checkoutDrawer .ant-drawer-content-wrapper .ant-drawer-header {
  border: 0 !important;
}

.underConstructionModal .ant-modal-body{
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  z-index: 1;
}

.downloadStoreText{
  display: none;
}

@media (min-width: 375px) {
  .downloadStoreText {
    display: block;
  }
}

