.hero {
    /* background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../images/mini/b.png');
    background: #063F71; */
    background: #F8FAFB;
    background: #f1fef4;
    background-position: left center;
    background-size: cover;
    height: 500px;
    display: flex;
    align-items: center;
    overflow: hidden;
}


.hero .round-me {
    background: #008060;
    height: 350px;
    width: 350px;
    border-radius: 50%;
    margin: 0px auto;
    display: block;
    position: relative;
}

.hero .round-me img {
    position: absolute;
    bottom: 0px;
    top: -50%;
}

.category-data.carousel-with-margin .bill-list {
    margin-bottom: 30px;
}

.category-data.carousel-with-margin .bill-list .image .category-image {
    cursor: pointer;
}

.bill.minor .contain {
    width: 100%;
}

.category-data.carousel-with-margin {
    margin-top: 5%;
}

.hero .hero_grid_block {
    flex: 1;
}

.hero .hero_grid_block img {
    width: 100%;
    height: auto;
    /* margin: 0% auto;
    display: block; */
}

.bill .hero .grid_2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.bill .hero .grid_2 h1 {
    font-size: 4.5rem;
}

.bill.wise9ja .hero .grid_2 {
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-columns: 1fr 1.5fr; */
}

.bill.wise9ja .hero .grid_2 img {
    width: 100%;
    margin: 0px auto;
    display: block;
}

.bill.wise9ja .hero .grid_2 a {
    margin-top: 2%;
    padding: 12px 35px;
    display: block;
    width: max-content;
}

.bill.wise9ja .hero button {
    padding: 12px 35px;
}

.bill.wise9ja .hero .contain h3 {
    font-size: 3.5rem;
}

.bill.wise9ja .hero .contain h3 span {
    color: #0000A4;
    font-weight: inherit;
    font-family: inherit;
}

.hero .contain h3 {
    font-size: 3rem;
    line-height: 1.2;
    width: 80%;
}

.hero .contain p {
    font-size: 1.8rem;
    width: 80%;
    line-height: 1.8;
    /* width: 100%; */
}

.how-wise9ja.hero ol li {
    font-size: 1.6rem;
    line-height: 1.8;
    list-style: none;
}

.hero .contain .grid_3 p {
    color: #000;
}

/* .hero .contain button {
    background: #fff;
    padding: 12px 25px;
    border-radius: 4px;
    border: 1px solid #fff;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    grid-gap: 10px;
} */

.bill.bill-page .hero .contain .icons-list a {
    background: #F2F4FA;
    padding: 12px 5px;
    border-radius: 16px;
    border: 1px solid #F2F4FA;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    color: #000000;
}

.bill.bill-page .hero .contain .icons-list a p {
    font-size: 1.4rem;
    margin-bottom: 0px;
    width: 100%;
}

.bill.bill-page .hero .contain .icons-list a .icon-cover.tv {
    width: 50%;
    margin: 0px auto;
    display: block;
}

.bill .hero .contain .app {
    margin-top: 10%;
}

.wise-icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    border-radius: 12px;
    height: 5rem;
}

.wise-icon-box.first {
    background: #D7F4FF;
}

.wise-icon-box.second {
    background: #FEECCA;
}

.wise-icon-box.third {
    background: #F4F0F6;
}

.grid_story_box .slick-box {
    display: grid;
    grid-template-columns: 1fr 6fr;
    align-items: center;
    grid-gap: 10px;
    margin-bottom: 10px;
}

.konnect_props .center h3 {
    margin: 0% auto;
    display: block;
    width: 30%;
    text-align: center;
}

.konnect_props .center h3 {
    color: #9B9B9B;
    margin-bottom: 37px;
    font-size: 2.3rem;
}

.konnect_props .center h3 span {
    color: #0000A4;
    font-weight: inherit;
    font-family: inherit;
}

.konnect_props .grid_3 .grid_story_box .slick-box img {
    width: 50%;
    height: auto;
    margin: 0%;
}

.konnect_props .grid_3 .grid_story_box h3 {
    margin: 0%;
}

.bill .hero .contain .app img {
    margin: unset;
}

.bill .hero a.bg_green {
    background: #258635;
    border-radius: 4px;
    margin-top: 10%;
    width: max-content;
    display: block;
    border-radius: 4px;
}

.bill .icon-cover img {
    width: 60%;
}

.bill .icon-cover.tv {
    background: #D7F4FF;
    padding: 15px 20px;
    border-radius: 16px;
}

.bill .icon-cover.cable {
    background: #F3E6CF;
    padding: 15px 20px;
    border-radius: 16px;
}

.bill .icon-cover.airtime {
    background: #E9EDF8;
    padding: 15px 20px;
    border-radius: 16px;
}

/* header */
.header {
    background: #063F71;
    min-height: 450px;
    display: flex;
    align-items: center;
    /* align-items: flex-end; */
}

.header img {
    height: auto;
    margin: 0% auto;
    display: block;
    /* height: 40rem; */
    object-fit: cover;
    width: 60%;
    object-position: top;
}

.header img.wise9ja_header {
    height: auto;
    margin: 0% auto;
    display: block;
    /* height: 40rem; */
    object-fit: cover;
    width: 75%;
    object-position: top;
}

.header h3 {
    font-size: 3rem;
    color: #fff;
    line-height: 1.4;
    width: 100%;
}

.header p {
    font-size: 1.5rem;
    line-height: 1.8;
    color: #fff;
}

.wise9ja .header p {
    font-size: 1.7rem;
}

/* .header a, .hero a {
    background: #008060;
    padding: 12px 25px;
    border-radius: 50px;
    border: 1px solid #008060;
    font-size: 1.5rem;
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
} */

.header small {
    display: block;
    margin-top: 15px;
    font-size: 1.2rem;
    color: #fff;
    font-family: 'Gilroy Light';
    word-spacing: 1px;
}

.button_flex {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
}

.header a.border_green {
    border: 2px solid #008060;
    color: #008060;
    background: transparent;
}

.header .get-app {
    margin-top: 2%;
}

.header .get-app img {
    margin: unset;
    height: 30%;
    width: 22%;
    height: auto;
}

/* colored_bg */
.colored_bg.green {
    padding: 3% 0;
    background: #7DCFE7;
}

.colored_bg.black {
    background: #000000;
    height: 40rem;
    overflow: hidden;
}

.wise9ja-content.colored_bg.black {
    background: #F8FAFB;
}

.wise9ja-content.colored_bg.black p {
    color: #838383;
}

.wise9ja-content.colored_bg.black .container h3 {
    color: #9B9B9B;
    width: 40%;
}

.wise9ja-content.colored_bg.black {
    height: max-content;
}

.wise9ja-content.colored_bg.black .container h3 span {
    color: #0000A4;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
}

.colored_bg.gray {
    background: #EDEDED;
    height: 40rem;
    overflow: hidden;
}

.colored_bg .grid_2 {
    align-items: unset;
}

.colored_bg.dark_blue {
    padding: 3% 0;
    background: #2047F4;
}

.colored_bg.green.gray {
    background: #F7F7F8;
    padding: 3% 0;
}

.colored_bg.green p {
    font-size: 1.8rem;
}

.colored_bg.black p {
    font-size: 1.7rem;
    color: #fff;
    line-height: 1.8;
}

.colored_bg.gray p {
    font-size: 1.8rem;
}

/* .colored_bg img {
    height: auto;
    width: 50%;
    width: 80%;
    margin: 0% auto;
    display: block;
} */

.colored_bg.gray h3 {
    font-size: 3rem;
    width: 80%;
    padding-top: 15%;
    line-height: 1.2;
}

.colored_bg .bg_white {
    /* padding: 10px 25px; */
    color: #000;
    font-size: 1.4rem;
    margin-top: 4%;
    width: max-content;
    display: block;
}

.new_content_dat .bg_green {
    display: block;
    margin-top: 4%;
    width: max-content;
}

.colored_bg.black h3 {
    font-size: 3rem;
    color: #fff;
    width: 80%;
    padding-top: 12%;
    line-height: 1.2;
}

.colored_bg.green .bg_white {
    padding: 8px 25px;
}

.colored_bg.green .grid_2 {
    margin-bottom: 3%;
}

/* konnect_props */
.konnect_props .first_show {
    width: 50%;
}

.konnect_props .first_show h3,
.konnect_props .bill_props h3 {
    font-size: 2.4rem;
    width: 60%;
    margin-bottom: 10px;
    line-height: 1.4;
    font-weight: 900;
}

.konnect_props .first_show p,
.konnect_props .bill_props p {
    font-size: 1.6rem;
    line-height: 1.7;
    margin-bottom: 40px;
}

.konnect_props .grid_3 p {
    font-size: 1.5rem;
    line-height: 1.8;
    margin-bottom: 0%;
}

.konnect_props .grid_3 h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.konnect_props .grid_3 img {
    width: auto;
    height: 5rem;
    margin-bottom: 10px;
}

/* sec */
.sec {
    background: #F7F7F8;
    text-align: center;
    padding: 3% 0;
}

.sec p {
    line-height: 1.7;
    font-size: 1.4rem;
    margin-bottom: 0%;
}

.konnect_products .grid_flex a {
    font-size: 1.5rem;
}

/* sec_data */
.sec_data p {
    font-size: 1.7rem;
    line-height: 1.8;
    /* color: #647084; */
}

.sec_data ul li {
    list-style: none;
}

.sec_data ul li p {
    font-size: 1.6rem;
    line-height: 1.5;
}

.sec_data ul li span {
    color: #fff;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background: #000;
    line-height: 1.5rem;
}

.sec_data img.main {
    width: 90%;
    /* margin: 0% auto;
    display: block; */
    height: 30rem;
    object-fit: cover;
    object-position: center top;
}

.sec_data img.download {
    width: 40%;
    height: auto;
}

.sec_data h3 {
    font-size: 3rem;
    width: 80%;
}

/* download_to_mobile */

.download_to_mobile.sec_data img.download {
    margin: unset;
}


@media screen and (max-width: 720px) {
    .konnect_props .first_show {
        width: 100%;
    }

    .konnect_props .grid_3 {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }

    .konnect_products .grid_6 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .colored_bg.green .grid_2 {
        display: flex;
        flex-direction: column-reverse;
    }

    .sec_data .grid_2 {
        display: block;
    }

    .sec_data img.download,
    .colored_bg.green img {
        height: auto;
        width: 60%;
    }

    .sec_data h3,
    .colored_bg.green h3 {
        font-size: 2rem;
        width: 100%;
        line-height: 1.6;
    }

    .sec_data p,
    .colored_bg.green p {
        font-size: 1.7rem;
    }
}


.div_bg_grey {
    background: #F6F6F6;
    position: relative;
    width: 70%;
    height: 100%;
    /* overflow: hidden; */
}

.div_bg_grey img {
    position: absolute;
    right: -10%;
    top: 10%;
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: center;
}

.new_content_data {
    height: 50rem;
}

.new_content_data .contai,
.new_content_data .grid_2 {
    height: 100%;
}

.colored_bg.green.last_content {
    padding: 3% 0;
}

.colored_bg.green.last_content img {
    height: auto;
    width: 35%;
    margin: 0% auto;
    display: block;
}

.colored_bg.green.last_content p {
    font-size: 1.7rem;
}

.faq_div .contain {
    width: 70%;
    margin: 0% auto;
    display: block;
}

.faq_div .ant-collapse-header {
    border-bottom: 1px solid #D8D6D5;
    font-size: 1.6rem;
}

.faq_div .first_message {
    text-align: center;
}

.faq_div .first_message h4 {
    font-size: 1.6rem;
    font-weight: 700;
}

.faq_div .first_message p {
    font-size: 1.8rem;
}


/* data_display */

.data_display h3 {
    font-size: 2.4rem;
    width: 30%;
    display: block;
    margin-bottom: 20px;
    line-height: 1.4;
    font-weight: 900;
}

.data_display .first_one p {
    width: 60%;
    font-size: 1.8rem;
    margin-bottom: 4%;
    line-height: 1.8;
}

.data_display .grid_4 p {
    /* width: 80%; */
    font-size: 1.5rem;
    margin-bottom: 0%;
    line-height: 1.8;
}

.data_display .grid_4 h4 {
    font-size: 1.8rem;
}

.data_display .grid_4 img {
    height: 6rem;
    margin-bottom: 10px;
    border-radius: 4px;
    width: auto;
}

/* .colored_bg.green.blue {
    background: #7DCFE7;
}

.colored_bg.green.dark_blue {
    background: #2047F4;
} */

.new_props .props_outline {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    text-align: center;
}

.new_props .props_outline h5 {
    margin-bottom: 5px;
    font-size: 1.5rem;
}

.new_props .props_outline p {
    margin-top: 5px;
    line-height: 1.7;
    font-size: 1.5rem;
    margin-bottom: 0px;
}

.new_props h3 {
    font-size: 2rem;
    margin-bottom: 50px;
    text-align: center;
}

.new_props img {
    height: 4rem;
    margin-bottom: 10px;
}

.vendor_form_grid {
    display: grid;
    grid-template-columns: 3fr 1.5fr;
    grid-gap: 10px;
}

.vendor_input_box {
    border-radius: 50px;
}

img.main,
img.download_main {
    width: 45%;
    height: auto;
    object-fit: cover;
    object-position: center;
}

img.download_main {
    margin: 0% auto;
    display: block;
}

.wise9ja-content.colored_bg.black img {
    width: 50%;
    height: auto;
    margin: 0% auto;
    display: block;
}

@media screen and (max-width: 720px) {

    .header {
        min-height: 600px;
        padding-bottom: 10%;
    }

    .header small {
        display: none;
    }

    .header .grid_2 {
        display: flex;
        flex-direction: column;
        grid-template-columns: repeat(2, 1fr);
        align-items: unset;
    }

    .header .grid_2 h3,
    .hero .contain h3,
    .bill.wise9ja .hero .contain h3 {
        font-size: 2.3rem;
        width: 100%;
    }

    .bill .hero a.bg_green {
        font-size: 1.3rem;
    }

    .header img {
        width: 80%;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .header .grid_2 p {
        font-size: 1.3rem;
        line-height: 1.7;
    }

    .header button.border_green {
        display: none;
    }

    .header small {
        font-size: .8rem;
    }

    .data_display h3,
    .konnect_props .bill_props h3 {
        width: 80%;
        font-size: 2rem;
        margin-bottom: 25px;
    }

    .konnect_props .bill_props h3 {
        margin-bottom: 15px;
    }

    .data_display .grid_4 p {
        width: 100%;
        font-size: 1.3rem;
        margin-bottom: 2%;
    }

    .data_display .grid_4 h4 {
        font-size: 1.6rem;
        margin-bottom: 5px;
    }

    .konnect_products .grid_flex a {
        display: none;
    }

    .data_display .grid_4 img {
        height: 5rem;
        margin-bottom: 10px;
        border-radius: 4px;
        width: auto;
    }

    /* .data_display .grid_4 {
        display: flex;
        flex-direction: column;
        align-items: unset;
        grid-gap: 10px;
    } */

    .data_display .grid_4 {
        grid-template-columns: repeat(2, 1fr);
        align-items: unset;
        grid-gap: 10px;
    }

    .colored_bg.green img {
        /* height: 30rem; */
        width: 100%;
        margin-bottom: 20px;
    }

    .colored_bg.green .grid_3 {
        display: block;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        grid-gap: 20px;
        display: none;
    }

    .konnect_products .grid_4 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        grid-gap: 10px;
    }

    .colored_bg.green {
        padding: 15% 0;
        padding-bottom: 10%;
    }

    .konnect_products h4 {
        font-size: 1.8rem;
        margin-bottom: 15px !important;
        font-weight: 600 !important;
        width: 100% !important;
    }

    .colored_bg .grid_2 {
        display: flex;
        flex-direction: column-reverse;
    }

    .colored_bg.black {
        height: max-content;
        padding-bottom: 15%;
    }

    .colored_bg.black h3,
    .wise9ja-content.colored_bg.black .container h3 {
        font-size: 2.2rem;
        width: 100%;
        line-height: 1.4;
    }

    .wise9ja-content.colored_bg.black {
        padding: 15% 0px;
    }

    .wise9ja-content.colored_bg.black img {
        height: auto;
        width: 70%;
    }

    .colored_bg.black p {
        font-size: 1.4rem;
    }

    .colored_bg.black .container {
        padding: 0 3%;
    }

    .sec_data p,
    .colored_bg.green p {
        font-size: 1.5rem;
    }

    .sec_data img.main {
        width: 100%;
        margin: unset;
        display: block;
        object-fit: cover;
        object-position: center;
        margin-bottom: 12%;
    }

    .sec_data.download img.main {
        height: auto;
        width: 70%;
    }

    .vendor_form_grid {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }

    .konnect_gift .konnect_props .first_show h3 {
        width: 80%;
    }

    .konnect_gift .sec_data p {
        font-size: 1.4rem;
    }

    .hero {
        height: max-content;
        padding-bottom: 10%;
        padding-top: 10%;
    }

    .hero .grid_2,
    .bill .hero .grid_2 {
        display: flex !important;
        flex-direction: column;
    }

    .hero .hero_grid_block img {
        width: 100%;
        height: auto;
        margin: 0% auto;
        padding-top: 10%;
        padding-top: 0px;
        padding-bottom: 0%;
        display: block;
    }

    .bill.bill-page .category-data.carousel-with-margin {
        margin-top: 15%;
    }

    .hero .hero_grid_block .icon-cover img {
        width: 50%;
    }

    .hero .hero_grid_block img.download {
        width: 65%;
        padding-top: 0px;
    }

    .hero .hero_grid_block .app h5 {
        padding-top: 5%;
        font-size: 1.7rem;
        width: 80%;
    }

    .bill .icon-cover {
        padding: 10px !important;
    }

    .bill .hero .contain button {
        padding: 10px 25px;
    }

    /* .hero .contain h3 {
        width: 100%;
        font-size: 2.2rem;
    } */

    .hero .contain p {
        width: 100%;
        font-size: 1.3rem;
    }

    .hero .bills-margin-top a {
        display: none;
    }

    .hero .icons-list button p {
        width: 100%;
        margin-bottom: 0%;
        font-size: 1.3rem !important;
        color: #000;
    }

    .konnect_props .first_show h3 {
        font-size: 2rem;
        width: 80%;
        margin-bottom: 10px;
        line-height: 1.4;
    }

    .konnect_props .first_show p {
        font-size: 1.5rem;
        line-height: 1.7;
        margin-bottom: 20px;
    }

    .konnect_props .grid_3 h3 {
        font-size: 1.5rem;
        margin-bottom: 5px;
    }

    .konnect_props .grid_3 p {
        font-size: 1.4rem;
        line-height: 1.7;
        margin-bottom: 0%;
    }

    .header a.desktop,
    .hero a.desktop {
        display: none;
    }

    .konnect_props .first_show p,
    .konnect_props .bill_props p {
        font-size: 1.5rem;
    }

    img.download_main {
        width: 60%;
        margin-bottom: 20px;
    }

    .konnect_props .center h3 {
        width: 100%;
        font-size: 2rem;
    }

    .grid_story_box.first-color,
    .grid_story_box.second-color,
    .grid_story_box.third-color {
        padding: 15px;
    }
}