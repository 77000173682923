.form .form-text h3.noSpace {
    margin-bottom: 5px;
    font-weight: 700;
}

.bg_grey .form_design_pages {
    display: flex;
    justify-content: center;
    min-height: 80vh;
    align-items: center;
}

.form .form-text p {
    line-height: 1.5;
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

.form .form-text h3 {
    font-size: 2rem;
    font-weight: 700;
}

.gif-corner {
    background: #E8E8E8;
    height: 20rem;
    border-radius: 4px;
    margin-bottom: 15px;
}

.ambassador-button-showcase.center-me {
    text-align: center;
}

.ambassador-button-showcase button {
    display: block;
    text-align: center;
    margin: 0px auto;
}

.ambassador-button-showcase button.bg_green {
    padding: 10px 25px;
    font-size: 1.3rem;
}

.ra-message p {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 25px;
}

.ra-message h3 {
    margin-bottom: 0px;
}

.ambassador-button-showcase button.bg_white {
    padding: 10px 25px;
    font-size: 1.3rem;
    border: 1px solid #E8E8E8;
    border-radius: 50px;
    margin-top: 10px;
}

.ambassador-button-showcase button.default {
    padding: 10px 25px;
    font-size: 1.4rem;
}

.email-verify-page .form_design_pages p {
    width: 100%;
    margin: 0px auto;
    display: block;
}

.email-verify-page .form_design_pages h5 {
    border-bottom: 2px solid #000;
    width: max-content;
    margin: 0px auto;
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.email-verify-page .center-text.emailverify ul li {
    font-size: 1.4rem;
    line-height: 1.8;
    list-style: none;
    margin-bottom: 20px;
}

.email-verify-page .center-text.emailverify ul li:last-child {
    margin-bottom: 0px;
}

.email-verify-page .center-text.emailverify ul li a,
.email-verify-page .center-text.emailverify ul li button {
    padding: 8px 35px;
    font-size: 1.3rem;
}

.email-verify-page .center-text.emailverify h3 {
    font-size: 1.5rem;
}

.email-verify-page .center-text.emailverify ion-icon {
    font-size: 2rem;
    color: var(--green);
}

.email-verify-page .center-text.emailverify.form_design_pages p {
    font-size: 1.5rem;
    margin-bottom: 30px !important;
    display: block;
}

.email-verify-page .center-text.emailverify ul li span {
    display: block;
    margin: 10px 0px;
}

.emailverify.center-text img {
    width: 45%;
    margin-top: 0px;
}

.wise9ja-form-data {
    text-align: center;
}

.wise9ja-form-data svg {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
}

.wise9ja-form-data p {
    font-size: 1.5rem;
}


@media screen and (min-width: 720px) {
    .bg_grey {
        background-color: #E8E8E8;
    }

    .div_bg_white {
        background: #fff;
        border-radius: 3px;
        width: 50%;
        margin: 0 auto;
        display: block;
        padding: 25px 15px;
        box-shadow: 0 0 2px rgb(0 0 0 / 40%);
        margin: 5% 0;
    }
}

@media screen and (max-width: 720px) {
    .form-pages .container {
        padding: 0 3%;
    }

    .signup-data-page .ra-message {
        padding-top: 10%;
    }

    .form .form-text h3 {
        padding-top: 15%;
        font-size: 2rem;
        font-weight: 700;
    }

    .form a,
    .form p {
        font-size: 1.3rem;
        margin-top: 5px;
    }

    .form {
        margin-bottom: 15%;
    }

    .email-verify-page p {
        width: 100%;
        margin: 0px auto;
        display: block;
    }

    .email-verify-page .center-text.emailverify .success {
        padding-top: 10%;
        text-align: left;
    }

    .email-verify-page .form_design_pages h5 {
        margin: unset;
        margin-bottom: 10px;
    }

    .email-verify-page .center-text.emailverify ul li a,
    .email-verify-page .center-text.emailverify ul li button {
        margin: unset !important;
    }

    .emailverify.center-text img {
        width: 65%;
        margin-top: 0px;
    }

    .email-verify-page .center-text.emailverify h3 {
        margin-top: 0px;
        padding-top: 0px;
    }
}