.new_shop_header {
    background: #b0e4d5;
    background: url('../images/shop/hero_banner.png');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 24rem;
}

.new_shop_header img {
    width: 100%;
    height: auto;
    display: none;
}


.ant-carousel .slick-dots {
    margin-left: 0% !important;
    margin-right: 0% !important;
}

.slick-dots .slick-dots-bottom {
    margin-left: 0% !important;
    margin-right: 0% !important;
}

.new_shop_header {
    background: #b0e4d5;
    background: url('../images/shop/hero_banner.png');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 30rem;
}

.new_shop_header img {
    width: 100%;
    height: auto;
    display: none;
}

.ant-card-body {
    padding: 12px !important;
}

@media screen and (max-width: 480px) {
    .ant-card-body {
        padding: 10px !important;
    }
}

.promo {
    margin-top: 3%;
}

.banner img {
    margin-top: 0;
    width: 100%;
    height: 15rem;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
}

/* products */
.product_image {
    /* position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    overflow: hidden;
    min-height: 10rem;
    max-height: 10rem;
    object-fit: contain; */
    background: #f5f3f1;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    max-height: 14rem;
    min-height: 14em;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
}

.product_image img {
    width: auto;
    -webkit-align-self: center;
    align-self: center;
    height: 5rem;
    height: 8rem;
}

/* .konnect_products h5,
.konnect_product_display h5 {
    font-size: 1.3rem;
    color: #121118;
    color: #111;
    line-height: 1.5;
    font-weight: 500;
    font-family: 'Gilroy Medium';
    margin-bottom: 0px;
} */

.konnect_products p.products_tag,
.konnect_product_display p.products_tag {
    font-size: 1.2rem;
    color: var(--green);
    font-weight: 500;
    margin-bottom: 0px;
    /* color: white; */
}

p.tag_container {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-right: 14px;
    padding-top: 8px;
    margin-left: auto;
    color: var(--green);
    font-weight: 500;
    margin-bottom: 0px;
    font-weight: bold;
}

.card_btn {
    padding: 0px;
    width: 100%;
    height: 100%;
}

.konnect_products span,
.konnect_product_display a span {
    font-size: 1.2;
    color: #4c4b58;
    font-weight: 500;
}

.konnect_products span.new_product_amount,
.konnect_product_display a span.new_product_amount {
    font-size: 1.4rem;
}

.konnect_products h4 {
    font-size: 2rem;
    margin-bottom: 10px;
    width: 35%;
}

.category-data .categories .category-image {
    background: #F7AD02;
}

.category-data .categories .category-image.Groceries {
    background: #a13623;
}

.category-data .categories .category-image.Beverages,
.category-data .categories .category-image.cleaning {
    background: #EADCD5;
}

.category-data .categories .category-image.Drinks,
.category-data .categories .category-image.kitchen {
    background: #016044;
}

.category-data .categories .category-image.Food {
    background: #F7AD02;
}

.category-data .categories .category-image.Oil {
    background: #AECBDB;
}

.category-data .categories .category-image.Groceries {
    background: #ECAA9E;
}

.category-data .categories .category-image.Toiletries {
    background: #D4575B;
}

.category-data .categories .category-image.For {
    background: #E1861D;
}

.category-data .categories .category-image {
    min-height: 10rem !important;
    max-height: 10rem !important;
    margin-bottom: 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

p.product_category_display {
    font-size: 13px;
    color: #4b566b;
}

.small_currency,
.currency {
    font-size: 1.3rem !important;
}

.center-spinner {
    display: flex;
    justify-content: center;
    height: 250px;
}

.center-spinner div {
    align-self: center;
    text-align: center;
}

.center-spinner div p {
    font-size: 1.6rem;
}

.center-spinner a {
    font-size: 1.4rem;
}

/* .carousel-image .product-image img, .product-image img  */
.category-data .categories .category-image img {
    width: auto;
    height: 6rem;
    object-fit: cover;
    object-position: center;
    position: absolute;
    -webkit-align-self: center;
    align-self: center;
}

.category-data p {
    font-size: 13px;
    color: #121118;
    line-height: 1.5;
    margin-bottom: 0px;
}

.cart .marginTop {
    padding-top: 5%;
}

.tile-header {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    word-spacing: -1px;
}

.cart .grid-flex h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 2px;
}

.cart-details .empty-cart .ant-empty-normal {
    margin: 10% 0;
}

.cart_message {
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.8;
}

.product-showcase {
    padding-top: 5%;
}

.cart-design-body {
    display: grid;
    grid-template-columns: 70fr 30fr;
    grid-gap: 50px;
}

.cart-design-tab {
    border: 1px solid #e1e3e4;
}

.cart-body-detail {
    display: grid;
    grid-template-columns: 90fr 10fr 10fr 10fr;
    grid-gap: 20px;
    border-bottom: 1px solid #e1e3e4;
    padding: 20px 0;
}

.cart-body-detail.no-border {
    border: none;
}

.cart-body-detail .inner-flex {
    display: flex;
    flex: 7 1;
}

.cart-body-detail .small-flex {
    flex: 1 1;
}

.cart-body-detail img {
    height: 6rem;
    width: auto;
    padding-right: 20px;
}

.cart-body-detail p.cart_product_description {
    font-size: 1.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cart .cart-brief .cart-brief-data {
    border: 1px solid #e1e3e4;
    background: #fff;
    width: 100%;
    max-height: 30rem;
    max-height: max-content;
    overflow: hidden;
    overflow-x: hidden;
    border-radius: 3px;
    padding: 3%;
}

.cart-message {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 5px;
    /* font-style: italic; */
}

.cart .cart-brief .cart-brief-data ul li,
.give_spacing_to_list li,
.new_list_for_new_checkout li,
ul.first-list.cart-list li {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 2px 0;
    line-height: 1.5;
    font-size: 1.3rem;
}

.cart .cart-brief .cart-brief-data ul li {
    font-size: 1.4rem;
}

.cart-body-detail .small-flex button {
    background: transparent;
    border: none;
    color: tomato;
    padding: 0 !important;
    font-size: 1.4rem;
}

.product-price {
    font-size: 1.4rem;
}

.product-showcase .grid_2 {
    grid-gap: 20px;
}

.product-showcase .grid_2 .product-display {
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    /* margin-left: 20%; */
    overflow: hidden;
    border-radius: 2px;
    background: #f8f9fa;
}

.product_image {
    position: relative;
}

.product_image .image-cart-button {
    position: absolute;
    right: 0px;
    background: #258635;
    padding: 5px 2px 3px 1px;
    width: max-content;
    width: 35px;
    border-radius: 3px;
}

.product_image .image-cart-button svg {
    width: 60%;
    stroke: rgba(253, 253, 253, .7);
}

.product-showcase .grid_2 .skeleton,
.product-showcase .grid_2 img.specific_product_image {
    height: 12rem;
    width: auto;
    object-fit: cover;
    object-position: center;
    position: absolute;
    -webkit-align-self: center;
    align-self: center;
}

.product-showcase img.likeEmoji {
    height: 3rem;
    margin-bottom: 5px;
}

.product-showcase span.product-price,
.product-showcase span.product-cashback {
    font-size: 1.5rem;
}

.product-showcase .product-desc {
    font-size: 1.5rem;
}

.product-showcase p.product-desc {
    font-size: 1.5rem;
    margin-top: 10px;
}

.product-showcase .shop_tags ul li {
    border: 1px solid #6b7177;
    border-radius: 50px;
    padding: 6px 25px;
    display: inline-block;
    margin: 2px;
    font-size: 13px;
    line-height: 1.5;
}

/* .category_header {
    background: #B0E4D5;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: flex-end;
} */

.category_header img {
    display: block;
    width: 100%;
    object-position: center right;
    object-fit: cover;
}

.by-category {
    margin-top: 3%;
}

.wishlist-display .grid_2 .item a {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    align-items: center;
}

.google-dowload {
    width: 40%;
    margin-top: 30px;
    height: auto;
}

.slick-track {
    margin: 0px !important;
}

.carousel-header {
    width: 100% !important;
    /* min-height: 450px; */
    display: flex !important;
    flex-direction: row;
    align-items: center;
    /* padding-left: 3%; */
    justify-content: space-between;
    /* background: #0D588E; */
}

.story-grid {
    margin-top: 5%;
}

.story-grid .story-grid-block {
    background: #a1bad8;
    padding: 15px 10px;
    border-radius: 4px;
    position: relative;
    /* height: 130px; */
}

.story-grid .story-block-inner-cover {
    display: grid;
    grid-template-columns: 2.2fr 1fr;
    align-items: center;
}

.story-grid .story-grid-block img.download.google-dowload {
    width: 70%;
}

.story-grid .story-grid-block h1 {
    font-size: 2rem;
    line-height: 1.4;
}

.story-grid .story-grid-block img.story-img {
    /* position: absolute; */
    width: 100%;
    height: auto;
    /* right: -30px; */
    top: 20px;
}

.story-grid a {
    background: #fff;
    padding: 5px 15px;
    border-radius: 4px;
    border: 1px solid #fff;
    font-size: 1.2rem;
    color: #111;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
    display: none !important;
}

.carousel-header .image-cont {
    width: 50% !important;
}

.carousel-header .content {
    width: 40%;
    width: 70%;
}

.carousel-header .content h1 {
    /* color: #fff; */
    font-size: 4.5rem;
    line-height: 1.5;
}

.carousel-header .content a {
    background: #258635;
    color: #fff;
    font-size: 1.5rem;
    padding: 15px 25px;
    border-radius: 4px;
}

.konnect_product_display h5,
.konnect_products h5 {
    color: #111;
    font-family: Gilroy Medium;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
}

button.products-button {
    background: #258635;
    padding: 4px 15px;
    border-radius: 50px;
    border: 1px solid #258635;
    font-size: 1.2rem;
    margin-top: 30px;
    color: #fff;
    display: flex;
    width: max-content;
    grid-gap: 10px;
}

button.products-button svg {
    width: 15%;
    stroke: #fff;
}

.konnect_product_display a span.new_product_amount,
.konnect_products span.new_product_amount {
    font-size: 1.4rem;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    display: none;
}

.shop-hero,
.home-hero {
    width: 100%;
    overflow: hidden;
}

.home_hero {
    background-image: url('../images/homepage/home_bg2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9faf5;
    padding: 50px 0px;
}

.rounded-button {
    border-radius: 3rem;
    padding: 10px 25px;
    background: #258635;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    outline: none;
    border: none;
}

.restaurant_banner2 {
    background-image: url('../images/homepage/restaurant_banner_2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.home_banner2 {
    background-image: url('../images/homepage/home_bg2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    background-color: #e9faf5;
    width: 100%;
    overflow: hidden;
    padding: 50px 0px;
}

.home_banner2 .center_content {
    padding: 0 2%;
    margin: 0px auto
}

.home_banner4 {
    background-image: url('../images/homepage/home_bg_4.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 5rem;
    height: 70vh;
    position: relative;
}


.center_content {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.center_content h1 {
    color: rgba(0, 0, 0, .85);
    font-size: 4rem;
    font-family: "Gilroy Bold";
    text-align: center;
    margin-bottom: 0px;
}

.home_banner4 h1 {
    font-size: 4rem;
}

.left_content {
    width: 40%;
    padding: 0px 2%;
}

.left_content h1 {
    color: rgba(0, 0, 0, .85);
    font-size: 4rem;
    font-family: "Gilroy Bold";
    margin-bottom: 0px;
}

.restaurant_slide_1 {
    background-image: url('../images/homepage/restaurant_slide_1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
}

.restaurant_slide_2 {
    background-image: url('../images/homepage/restaurant_slide_2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
}


.slide_1 {
    background-image: url('../images/homepage/slide_2.png');
}

.slide_2 {
    background-image: url('../images/homepage/slide_3.png');
    padding: 3rem 6rem;
}

.slide_1,
.slide_2{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slide_1 {
    padding: 3rem 0rem 0rem 6rem;
}

.slider_content h1 {
    color: #fff;
    /* font-size: 4rem; */
    font-family: "Gilroy Bold";
    margin-bottom: 0px;
}

.rice {
    display: block;
}

.restaurant_card {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6.5rem 3rem
}

.restaurant-card {
    padding: 0px !important;
    border: none;
    border-radius: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.restaurant-card img {
    width: 100%;
    border-radius: 16px 16px 0 0;
}

.restaurant-card h3 {
    font-weight: bold;
    margin: 16px 0 8px;
}

.restaurant-card p {
    margin: 0;
    color: #959595;
}

.restaurant-card .card-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 16px;
}

.align-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-card-body {
    padding: 0px !important;
}

.main_restaurant_container {
    padding: 30px;
}

.restaurant-banner {
    background-color: white;
    padding: 6rem 3rem;
    border-radius: 50px;
}

.slider-container {
    margin: 0 auto;
    padding: 0 15px;
}

.wrap_buttons {
    display: flex;
    margin-top: 20px;
    gap: 10px;
}

.home_side_image {
    max-height: 200px;
    margin-left: auto;
    display: block;
}

.home_side_image img {
    max-height: 235px;
}

.home_banner4_content {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.disapear_small {
    display: block
}

.hide_large_screen {
    display: none;
}

@media screen and (min-width: 720px) and (max-width: 1024px) {
    .center_content {
        width: 70%;
    }

    .left_content {
        width: 50%;
    }

}

@media screen and (max-width: 720px) {
    .hide_large_screen {
        display: block;
        width: 100%;
    }

    .home_banner2 {
        background-image: none;
        flex-direction: column;
        padding: 0px 0px 35px 0px !important;
    }

    .home_banner2 .center_content {
        padding: 0 2%;
        margin: 30px auto 0px auto;
    }

    .disapear_small {
        display: none
    }

    .slide_1,
    .slide_2,
    .slide_3 {
        height: 200px;
        border-radius: 10px;
        padding: 2rem 3rem;
    }

    .home_banner4_content {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: start;
    }

    .home_banner4 {
        background-image: url('../images/homepage/home_buttom_mobile.png');
        background-size: cover;
        background-position: top;
    }

    .wrap_buttons {
        flex-direction: column;
    }

    .home_side_image {
        display: none;
    }

    .slider_content h1 {
        /* font-size: 2rem; */
    }

    .main_restaurant_container {
        padding: 10px;
    }
/* 
    .restaurant-banner {
        padding: 3rem 1.5rem;
    } */

    .restaurant_card {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem 1rem
    }

    .rice {
        display: none !important;
    }

    .left_content {
        width: 85%;
        padding: 5% 2%;
    }

    .center_content {
        width: 100%;
    }

    .center_content h1 {
        font-size: 3rem;
    }

    .home_banner4 h1 {
        font-size: 3rem;
        margin-bottom: 1rem;
    }

    .home_banner4 h1 span {
        font-size: 2rem;
        font-weight: bold;
    }

    .shop-hero,
    .home-hero {
        height: unset;
    }

    .home-hero {
        height: unset;
    }

    .story-grid {
        margin-top: 15%;
    }

    .shop-hero img,
    .home-hero img {
        object-fit: cover;
        object-position: center;
        border: none;
        outline: none;
    }

    .shop-hero .carousel-header .content h1,
    .home-hero .carousel-header .content h1 {
        font-size: 2.8rem;
        line-height: 1.6;
        margin-bottom: 10px;
    }

    .grid-flex h3 {
        margin-bottom: 0px;
    }

    .shop-hero .google-dowload,
    .home-hero .google-dowload {
        width: 70%;
        margin: 10px auto;
        display: block;
    }

    .shop-hero .carousel-header .content a,
    .home-hero .carousel-header .content a {
        margin-top: 20px;
        padding: 10px 25px;
    }

    .carousel-header .content {
        width: 100%;
    }

    .carousel-header img {
        /* width: 100% !important; */
        /* min-height: 450px; */
        height: 200px !important;
        object-fit: cover;
        object-position: center;
        display: flex !important;
        /* flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        padding-top: 6rem; */
    }

    .carousel-header .image-cont {
        width: 100% !important;
    }

    .category_header {
        height: 20rem;
        display: flex;
        background: #B0E4D5;
        align-items: flex-end;
        position: relative;
        overflow: hidden;
    }

    .category_header img {
        height: 15rem;
        position: absolute;
        bottom: -10px;
        right: -20%;
    }

    .new_shop_header {
        background: url('../images/shop/hero_banner.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 20rem;
    }

    .promo {
        margin-top: 10%;
    }

    .promo .grid_4 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .grid_6 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
    }

    /* .grid_6 .item h5.product_name {
        padding-left: 0;
        font-size: 1rem;
        font-weight: 400;
        overflow: hidden;
    } */
    .grid_6 .item h5.product_name {
        font-size: 1rem;
        font-weight: 400;
        overflow: hidden;
        padding-left: 0;
    }

    .product_new_desc .new_product_amount,
    .product_new_desc .currency,
    .konnect_product_display a span.new_product_amount {
        font-size: 1.2rem;
        color: #767472;
    }

    .konnect_product_display a span.new_product_amount,
    .product_new_desc .currency,
    .product_new_desc .new_product_amount {
        color: #767472;
        font-size: 1.2rem;
    }

    .flex_my_amount {
        margin: 0;
        margin-bottom: 5px;
    }

    .props p.products_tag {
        margin-bottom: 0px;
        padding-bottom: 0px;
        color: #258635;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.4;
        width: 48%;
    }

    /* product */
    .product-showcase .grid_2 {
        display: block;
    }

    .product-showcase .grid_2 .product-display {
        width: 100%;
        height: 35rem;
        margin-left: 0%;
    }

    .product-showcase .shop_tags ul li {
        border: 1px solid #6b7177;
        border-radius: 50px;
        padding: 5px 25px;
        display: inline-block;
        margin: 2px;
        font-size: 1rem;
        line-height: 1.5;
    }

    .product_main {
        margin-top: 5%;
    }

    .likeEmojiDiv {
        background: #F8F9FA;
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        border-radius: 50px;
        text-align: center;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .product-showcase .grid_2 img {
        height: 2rem;
        width: auto;
        margin-bottom: 0%;
    }

    .konnect_product_display {
        margin-top: 10%;
        margin-top: 0px;
    }

    .cart-design-body {
        display: block;
    }

    .cart-design-body .cart-design-tab {
        margin-bottom: 20px;
    }

    .cart-design-body .cart-body-detail {
        display: block;
        padding: 20px 0px;
    }

    .cart-design-body .prevOrders.cart-body-detail .inner-flex.new_cart {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-gap: 10px;
        /* align-items: center; */
    }

    .cart-design-body .prevOrders.cart-body-detail .ant-select-selector {
        padding-top: 0% !important;
        margin-top: 0%;
    }

    .cart-design-body .prevOrders.cart-body-detail .cart_product_text {
        align-items: center;
    }

    .prevOrderData .previous_orders ul li {
        line-height: 2.1;
    }

    .cart-design-body .prevOrders.cart-body-detail .mobile-flex-cover {
        display: unset;
    }

    .cart-design-body .prevOrders.cart-body-detail .cart_product_text h4 {
        margin-bottom: 0%;
    }

    .cart-design-body .prevOrders.cart-body-detail .cart_product_text p {
        margin-bottom: 0%;
    }

    .prevOrders.cart-body-detail .inner-flex.new_cart div.cart_product_image {
        width: 100%;
    }

    .cart-design-tab {
        border: none;
    }

    .small-flex {
        display: none;
    }

    .mobile-flex-cover {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .mobile-flex {
        display: flex;
        align-items: center;
    }

    .mobile-flex p {
        margin: 0%;
        padding-left: 15px;
        font-weight: 500;
        font-size: 1.3rem;
    }

    .mobile-flex-cover button {
        background: transparent;
        border: none;
        color: tomato;
        outline: none;
        font-size: 11px;
        font-weight: 600;
    }

    .cart-body-detail .inner-flex.new_cart div.cart_product_image img {
        height: 3rem;
        width: auto;
        object-fit: cover;
        object-position: center;
        position: absolute;
        align-self: center;
        padding-right: 0px;
    }

    .cart-body-detail .inner-flex.new_cart div.cart_product_image {
        flex: 1 1;
        margin-bottom: 10px;
        background: #F5F3F1;
        width: 20%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        min-height: 5rem;
        max-height: 5rem;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .cart-body-detail .inner-flex {
        display: flex;
        align-items: center;
        flex: 7 1;
    }

    .cart-body-detail .inner-flex.new_cart {
        align-items: unset;
    }

    .cart .marginTop {
        margin-top: 10%;
    }

    .product-showcase .grid_flex {
        display: flex;
        justify-content: space-between;
    }

    .cart_product_text {
        flex: 3;
    }

    .inner-flex.new_cart {
        display: block;
    }

    .product-showcase p.product-desc {
        font-size: 1.4rem;
        line-height: 1.8;
        margin-top: 10px;
    }

    .product-showcase .shop_tags {
        display: none;
    }

    .cart-details .empty-cart .ant-empty-normal {
        margin: 40% 0;
    }

    .cart-body-detail p.cart_product_description {
        font-size: 1.3rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .ant-collapse-content>.ant-collapse-content-box {
        padding: 16px 8px;
    }

    .ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
        padding-left: 8px;
    }

    .banner.cash,
    .banner img {
        height: 10rem;
        object-position: left;
        object-fit: cover;
    }
}