.footer {
    background: #171717;
}

.first-footer {
    border-top: .5px solid #272424;
}

.first-footer input {
    border: 1px solid #b5b5b5;
    background: transparent;
    color: #fff;
    height: 4rem;
    border-radius: 50px 0px 0px 50px;
}

.first-footer form div {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    width: 80%;
}

.first-footer form button {
    background: #258635;
    border: 1px solid #258635;
    color: #fff;
    font-size: 1.3rem;
    border-radius: 0px 50px 50px 0px;
}

.first-footer form p {
    margin-bottom: 0%;
}

.first-footer .contain ul li {
    display: inline-block;
    padding-right: 20px;
}

.first-footer .contain ul {
    margin: 0px;
}

.first-footer .contain ul li a,
.first-footer .contain ul li p {
    color: #b5b5b5;
    font-size: 1.2rem;
    margin-bottom: 0px;
}

.second-footer .contain,
.first-footer .contain {
    padding-top: 3%;
    padding-bottom: 2%;
}

.second-footer .contain ul li {
    list-style: none;
}

.second-footer .contain ul li a,
.second-footer .contain ul li p,
.second-footer .contain p {
    line-height: 150%;
    font-size: 1.2rem;
    color: #B5B5B5;
    cursor: pointer;
    line-height: 1.5;
}

.second-footer .contain p {
    line-height: 1.6;
    font-size: 14px;
    width: 95%;
}

.second-footer .contain ul li {
    padding-bottom: 5px;
}

.second-footer img {
    cursor: pointer;
}

.second-footer .contain h6,
.first-footer .contain h6 {
    font-weight: 600;
    font-size: 95%;
    color: #fff;
    clear: both;
    padding-top: 10px;
    letter-spacing: 0.02px;
}

.second-footer .contain h6.connect {
    margin-top: 60px;
    margin-bottom: 20px;
}

.second-footer {
    /* background: #5A31F4; */
    background: #2E2E2E;
    background: #171717;
    /* padding-top: 15px; */
}

.second-footer i {
    color: #fff;
    padding: 7px 10px;
    margin-right: 20px;
    margin-top: 15px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid #fff;
}

/* .second-footer h6 {
    color: #fff;
    
    display: block;
    font-weight: 400;
} */

.flex-footer-4 {
    display: flex;
}

.grid-footer-4 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
}

.flex-footer-4 .first-side {
    flex: 1;
}

.flex-footer-4 .first-side h6.social {
    margin-bottom: 25px !important;
}

.flex-footer-4 .second-side {
    flex: 2;
}

.footer .second-footer .contain img {
    width: 100% !important;
    height: auto;
    /* max-height: 4.5rem !important;
    min-height: 4.5rem !important;
    border-radius: 2px;
    overflow: hidden !important;
    padding-bottom: 5%;
    */
    object-fit: cover;
    object-position: center;
}

.footer_download_button {
    margin-bottom: 10px !important;
}

.footer.noSpace {
    margin-top: 0% !important;
}

.footer .second-footer .contain h6 {
    margin-bottom: 5px;
    font-size: 1.2rem;
}

.footer .first-footer .show-flex {
    display: flex;
}

.footer .first-footer i {
    color: #2E2E2E;
    background: #fff;
    height: 3rem;
    width: 3rem;
    padding: 5px;
    text-align: center;
    font-size: 165%;
    margin-right: 10px;
    border-radius: 50%;
    margin-top: 10px;
}

.first-footer .contain {
    padding-bottom: 2%;
}

.footer ul li {
    font-size: 105%;
    line-height: 1.5;
}

@media screen and (max-width: 720px) {
    .second-footer .contain p {
        line-height: 1.6;
        font-size: 13px;
        width: 95%;
    }

    .second-footer .contain ul li a,
    .first-footer .contain p,
    .second-footer .contain ul li p,
    .second-footer .contain p {
        line-height: 150%;
        font-size: 13px;
        color: #B5B5B5;
        cursor: pointer;
        line-height: 1.5;
    }

    .footer .second-footer .contain h6 {
        margin-top: 25px;
    }

    .footer {
        clear: both;
        /* margin-top: 15% !important; */
    }

    .footer.noSpace {
        margin-top: 0% !important;
    }

    .footer .first-footer .show-flex {
        display: block;
    }

    .footer .first-footer .show-flex i {
        display: none;
    }

    .footer .second-footer .flex-footer-4 {
        display: block;
    }

    .footer ul li {
        font-size: 115%;
        line-height: 1.5;
    }

    .grid-footer-4 {
        display: block;
        padding-bottom: 10%;
    }

    .footer .second-footer .contain img {
        height: auto;
        width: 60% !important;
    }

    .footer .first-footer .grid_2 {
        display: block;
    }

    .grid_2 {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        display: grid !important;
    }

    .first-footer .contain ul li {
        line-height: 2.2;
    }

    .first-footer {
        padding-top: 5%;
    }

    .first-footer .contain ul {
        margin-top: 5%;
    }

    .first-footer .contain ul li a {
        font-size: 1.2rem;
    }

    .first-footer .contain p {
        font-size: 1.2rem;
    }

    .footer .first-footer .grid_2 {
        display: flex !important;
        flex-direction: column;
        align-items: unset;
    }
}

footer {
    margin-top: 555% !important;
}